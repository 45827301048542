/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    h1: "h1",
    h2: "h2",
    p: "p",
    ol: "ol",
    li: "li",
    a: "a"
  }, _provideComponents(), props.components);
  return React.createElement(React.Fragment, null, React.createElement(_components.h1, null, "Yamaha Develops Hydrogen-Powered Prototype Outboard Engine for Boats"), "\n", React.createElement(_components.h2, null, "Introduction"), "\n", React.createElement(_components.p, null, "According to recent research, Yamaha has made significant strides in developing a hydrogen-powered prototype outboard engine for boats. This initiative is part of the company's commitment to achieving carbon neutrality in its marine products by exploring new energy sources and cutting-edge technologies."), "\n", React.createElement(_components.h2, null, "Prototype Details"), "\n", React.createElement(_components.p, null, "The hydrogen-powered engine utilizes fuel cells to generate electricity, offering a more environmentally friendly and efficient alternative to traditional gasoline engines. Yamaha officially unveiled the prototype at the Miami International Boat Show in 2024, showcasing its dedication to sustainable marine solutions."), "\n", React.createElement(_components.h2, null, "Future Plans"), "\n", React.createElement(_components.p, null, "While Yamaha plans to continue refining and advancing this hydrogen-powered technology, there are lingering concerns regarding the feasibility of hydrogen for outboard motors. In parallel efforts to bolster its eco-friendly product lineup, Yamaha is also in the process of acquiring the electric outboard company Torqeedo."), "\n", React.createElement(_components.p, null, "For more information, you can refer to the following sources:"), "\n", React.createElement(_components.ol, null, "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://news.yamaha-motor.co.jp/news/2023/1207/proto-obm.html"
  }, "Yamaha Motor to Exhibit Prototype Hydrogen Outboard Motor at Miami International Boat Show")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://yamahaoutboards.com/newsroom/company-news/yamaha-develops-hydrogen-fuel-system-with-roush-and-regulator-marine-hydrogen-outboard-unveiled-at"
  }, "Yamaha Develops Hydrogen Fuel System with Roush and Regulator Marine, Hydrogen Outboard Unveiled at Miami International Boat Show")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://newatlas.com/marine/yamahas-hydrogen-outboard-boat-prototype/"
  }, "Yamaha's world-first hydrogen outboard unveiled on prototype boat")), "\n"));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
